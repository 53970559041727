import React, { useState } from 'react';
import { Select, MenuItem, ListSubheader, Input } from '@material-ui/core';
import './MultiSelectQuestion.scss';

const MultiSelectQuestion = ({
  options = [],
  onChangeText,
  placeholder,
  customInputPlaceholder,
  selectedMultipleItems = []
}) => {
  const customInputedOption =
    selectedMultipleItems.filter(item => !options.includes(item))?.[0] || '';
  const selectedItemsWithoutCustom = selectedMultipleItems.filter(item =>
    options.includes(item)
  );
  const [customOption, setCustomOption] = useState(customInputedOption);
  const [selectedItems, setSelectedItems] = useState(
    selectedItemsWithoutCustom
  );

  const handleChangeCustomOption = (e, prevValue) => {
    const _value = e.currentTarget.value;
    setCustomOption(_value);

    let updatedSelectedItems = [...selectedItems];
    if (updatedSelectedItems.includes(prevValue)) {
      updatedSelectedItems = updatedSelectedItems.map(
        item => item === prevValue && _value
      );
    } else {
      updatedSelectedItems.push(_value);
    }
    onChangeText(updatedSelectedItems.join(', '));
  };

  const handleMultiSelecton = e => {
    const _value = e.target.value.filter(x => x);
    setSelectedItems(_value);
    onChangeText(_value.map(x => x.trim()).join(','));
  };

  const getValueWithCustomOption = () => {
    if (selectedItems.length > 0 && customOption) {
      return `${selectedItems.map(x => x.trim()).join(', ')}${', ' +
        customOption.trim()}`;
    } else if (selectedItems.length > 0) {
      return selectedItems.map(x => x.trim()).join(', ');
    } else {
      return customOption;
    }
  };

  return (
    <Select
      multiple
      value={selectedItems.concat('')}
      onChange={e => handleMultiSelecton(e)}
      placeholder={placeholder}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        getContentAnchorEl: null
      }}
      className={`multi-select-question`}
      renderValue={() => (
        <div className="render-value">
          {getValueWithCustomOption(selectedItems, customOption)}
        </div>
      )}
    >
      {options.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
      <ListSubheader>
        <Input
          style={{ width: '100%' }}
          value={customOption}
          onChange={e => handleChangeCustomOption(e, customOption)}
          placeholder={customInputPlaceholder}
          required
          onKeyDown={e => e.stopPropagation()}
        />
      </ListSubheader>
    </Select>
  );
};

export default MultiSelectQuestion;
