import { useEffect, useState, useCallback } from 'react';
import { isEmpty } from 'lodash';

const saveHistoryEndpoint = `${process.env.REACT_APP_BACKEND_HTTP_URL}/save_data/`;

const useAnalytics = ({ onSave }) => {
  const sessionId = Date.now();
  const [actionHistory, setActionHistory] = useState([]);
  const [shouldSaveHistory, setShouldSaveHistory] = useState(false);

  const getSelectedOption = useCallback(options => {
    return (options || [])
      .filter(item => item.selected)
      .map(item => item.title)
      .join(', ');
  }, []);

  const recordHistory = useCallback(
    (type, field, value, replaceExisting = false) => {
      if (!value || (typeof value === 'object' && isEmpty(value))) {
        return;
      }
      const historyItem = {
        type: type || 'input',
        field,
        value
      };

      if (field === 'estimate' || field === 'estimateFeature') {
        setShouldSaveHistory(true);
      }

      setActionHistory(a => {
        if (replaceExisting) {
          const index = a.findIndex(item => item.field === field);
          if (index !== -1) {
            a.splice(index, 1, historyItem);
            return a;
          }
        }
        return [...a, historyItem];
      });
    },
    []
  );

  const saveHistory = useCallback(() => {
    const body = {
      session_id: sessionId.toString(),
      query: {
        data: actionHistory.filter(item => item.type !== 'result')
      },
      results: {
        data: actionHistory.filter(item => item.type === 'result')
      }
    };
    fetch(saveHistoryEndpoint, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then(() => {
        setActionHistory(s => s.filter(item => item.type !== 'result'));
      })
      .catch(() => {
        console.log('Failed to save data.');
      });
  }, []);

  useEffect(() => {
    console.log('Action history', actionHistory);
    if (shouldSaveHistory) {
      saveHistory();
      setShouldSaveHistory(false);
      onSave();
    }
  }, [actionHistory, onSave, saveHistory]);

  return {
    setActionHistory,
    getSelectedOption,
    recordHistory,
    saveHistory
  };
};

export default useAnalytics;
