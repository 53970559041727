import React from 'react';
import Image from '../Image';
import './BuildEstimate.scss';

const BuildEstimate = () => (
  <section className="section-block-img-text">
    <div className="block-img-text-and-btn-wrap">
      <Image src="/images/build-estimate-bg" />
      <div className="block-img-text-and-btn-content">
        <div className="block-img-text-and-btn-title text-link list-content">
          <h2>Your estimate is ready – let's build your project together!</h2>
        </div>
        <div className="block-img-text-and-btn-text text-link list-content">
          <p>
            Now that you know your project’s budget, it’s time to bring your
            vision to life. Ready to get started? Let our expert team handle the
            development and deliver a solution tailored to your needs.
          </p>
        </div>
        <div className="talk">
          <a
            href="https://calendly.com/o-konoval/30min"
            className="btn-yellow"
            rel="noopener noreferrer"
            target="_blank"
          >
            Let’s talk
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default BuildEstimate;
