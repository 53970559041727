import React, { useCallback } from 'react';
import Select from '../Select';
import Image from '../Image';
import './PlatformCard.scss';

const PlatformCard = ({
  selected,
  title,
  icon,
  note,
  onChange,
  isFetching,
  index
}) => {
  const handleSingleSelectClick = useCallback(() => {
    onChange(index, !selected);
  }, [index, onChange, selected]);
  return (
    <button
      className={`platform-card${selected ? ' selected' : ''}`}
      onClick={handleSingleSelectClick}
      disabled={isFetching}
    >
      <div style={{ display: 'flex' }}>
        <Image src={`/images/${icon}`} className="icon" />
        {selected ? <Select selected={selected} platform /> : null}
        <h3 className="title">{title}</h3>
      </div>
      <p className="note">{note}</p>
      {selected && <span className="arrow" />}
    </button>
  );
};

export default PlatformCard;
