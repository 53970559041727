import React, { useState, useRef, useEffect } from 'react';
import './Faq.scss';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const cardBodyRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "Who is the AI Estimator designed for?",
      answer: "The AI Estimator is perfect for startup founders, entrepreneurs, and businesses that need a fast and detailed project plan. It helps streamline decision-making and planning by providing reliable, data-driven estimates."
    },
    {
      question: "Can I further customize my estimate?",
      answer: "Yes, you can adjust the project details or re-run the estimator to see how changes in features or scope affect the overall cost and timeline. This allows you to fine-tune the estimate to match your needs."
    },
    {
      question: "How does the AI Estimator handle complex projects?",
      answer: "The AI Estimator can manage both simple and complex projects. For larger or more intricate solutions, it breaks the project down into manageable stages, giving clear insights into the costs and timelines for each phase."
    },
    {
      question: "Is the data I provide secure?",
      answer: "Yes, we take your privacy seriously and ensure that all the data you input into the AI Estimator is handled securely and confidentially."
    },
    {
      question: "Can I speak with an expert after getting my estimate?",
      answer: "Absolutely! After receiving your estimate, you can connect with our team to discuss the details of your project further. Our experts are available to help guide you through the next steps and bring your vision to life."
    }
  ];

  useEffect(() => {
    cardBodyRefs.current.forEach((ref, index) => {
      if (ref) {
        if (activeIndex === index) {
          ref.style.height = `${ref.scrollHeight + 16}px`;
        } else {
          ref.style.height = '0';
        }
      }
    });
  }, [activeIndex]);

  return (
    <section className="col-9 section-faq">
      <div className="services-title">
        <div className="sticky">
          <h2 className="font--h2-big" id="blog-preloader">
            Frequently Asked
            <br /> Questions
          </h2>
          <img
            className="desc"
            src="https://ik.imagekit.io/k0jemnbco/fintech/Group_22969.webp"
            width="295"
            height="265"
            alt="Hand"
            loading="lazy"
          />
        </div>
      </div>
      <div className="accordion" id="faq-accordion">
        {faqData.map((item, index) => (
          <div className="card" key={index}>
            <h5
              id={`faq-accordion-${index}`}
              className={`card-header rounded-40 font--h4 ${activeIndex === index ? 'active' : 'collapsed'}`}
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <div className="card-header-more"></div>
            </h5>
            <div
              className="card-body"
              ref={(el) => (cardBodyRefs.current[index] = el)}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;