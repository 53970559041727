import Axios from 'axios';

const validateEmail = email => {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return regex.test(email);
};

export const sendEmailEstimate = async ({ userName, email, table }) => {
  try {
    if (!userName.length || !email.length || !validateEmail(email))
      throw new Error('EMPTY FILEDS');
    const res = await Axios(
      `${
        process.env.REACT_APP_BACKEND_HTTP_URL
      }/send_email_estimates?email=${encodeURI(email)}&name=${encodeURI(
        userName
      )}`,
      {
        method: 'POST',
        data: table
      }
    );

    console.log(res, 'body');
    return res;
  } catch (error) {
    console.log(error, 'error');
    return false;
  }
};
