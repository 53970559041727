import React, { useCallback, useEffect, useState } from 'react';
import Image from '../Image';
import './AIQuestionsCard.scss';
import { Input, MenuItem, Select, ListSubheader } from '@material-ui/core';

const AIQuestionsCard = ({
  text,
  title,
  icon,
  note,
  onChangeText,
  index,
  options,
  placeholder,
  isMultiple,
  customRolePlaceholder,
  isFetching
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [customOption, setCustomOption] = useState('');

  const [selectedItem, setSelectedItem] = useState(text || '');

  useEffect(() => {
    if (!text.length) {
      setSelectedItems([]);
      setCustomOption('');
    }
  }, [text]);

  const handleSingleSelectClick = useCallback(
    event => {
      onChangeText(index, event.target.value);
      setIsOpen(false);
    },
    [index, onChangeText]
  );

  const handleDetails = useCallback(
    event => {
      event.preventDefault();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  const handleSelectChange = useCallback(
    event => {
      const selectedValue = event.target.value;
      setSelectedItem(selectedValue);
      onChangeText(index, selectedValue);
      handleDetails(event);
    },
    [index, onChangeText]
  );

  const handleMultiSelecton = (e, index) => {
    const _value = e.target.value.filter(x => x);
    setSelectedItems(_value);
    onChangeText(index, _value.map(x => x.trim()).join(','), _value);
  };

  const handleChangeCustomOption = (e, prevValue, index) => {
    const _value = e.currentTarget.value;
    setCustomOption(_value);

    let updatedSelectedItems = [...selectedItems];
    if (updatedSelectedItems.includes(prevValue)) {
      updatedSelectedItems = updatedSelectedItems.map(
        item => item === prevValue && _value
      );
    } else {
      updatedSelectedItems.push(_value);
    }
    onChangeText(index, updatedSelectedItems.join(', '), updatedSelectedItems);
  };

  const getValueWithCustomOption = (selectedItems, customOption) => {
    if (selectedItems.length > 0 && customOption) {
      return `${selectedItems.map(x => x.trim()).join(', ')}${', ' +
        customOption.trim()}`;
    } else if (selectedItems.length > 0) {
      return selectedItems.map(x => x.trim()).join(', ');
    } else {
      return customOption;
    }
  };

  return (
    <div className={`AIQuestionsCard-card`}>
      <div>
        <Image src={`/images/${icon}`} className="icon" />
        <h3 className="title">{title}</h3>
        <p className="note">{note}</p>
      </div>
      {isMultiple && options ? (
        <Select
          disabled={isFetching}
          multiple
          value={selectedItems.concat('')}
          onChange={e => handleMultiSelecton(e, index)}
          placeholder={placeholder}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          renderValue={() => (
            <div className="render-value">
              {getValueWithCustomOption(selectedItems, customOption)}
            </div>
          )}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          <ListSubheader>
            <Input
              style={{ width: '100%' }}
              value={customOption}
              onChange={e => handleChangeCustomOption(e, customOption, index)}
              placeholder={customRolePlaceholder}
              required
              onKeyDown={e => e.stopPropagation()}
            />
          </ListSubheader>
        </Select>
      ) : options ? (
        <Select
          value={selectedItem}
          onChange={handleSelectChange}
          displayEmpty
          disabled={isFetching}
          renderValue={selected => (selected.length ? selected : placeholder)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Input
          disabled={isFetching}
          value={text}
          onChange={handleSingleSelectClick}
          placeholder={placeholder}
          required
          className="AIQuestionsCard-input"
          disableUnderline={true}
        />
      )}
    </div>
  );
};

export default AIQuestionsCard;
