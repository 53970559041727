import React from 'react';
import Image from '../Image';
import './Description.scss';

const Description = () => (
  <div className="description">
    <div className="block">
      <div className="tag-wrapper">
        <p className="font--12">Estimator</p>
      </div>
      <h1 className="font--h2-big">
        Accurately estimate your technology solution with AI
      </h1>
      <p className="font--16">
        Looking for a quick and precise way to estimate your tech project?
        Geniusee’s AI-powered Estimator delivers in minutes. Simply choose your
        project’s features and details and receive a comprehensive estimate
        covering costs, development time, features, and a full breakdown of
        functionalities. It’s perfect for businesses needing realistic,
        actionable insights to plan smarter and move faster!
      </p>
      <div className="badge-image">
        <a
          href="https://www.producthunt.com/posts/geniusee-ai-estimator?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-geniusee-ai-estimator"
          target="_blank" rel="noopener noreferrer">
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=488851&theme=light"
            alt="Geniusee AI Estimator - Features estimate and budget of new product ideas in minutes | Product Hunt"
            style={{width: '250px', height: '54px'}}
            width="250"
            height="54"
          />
        </a>
      </div>
    </div>
    <div className="image">
      <Image src="/images/estimation"/>
    </div>
  </div>
);

export default Description;