import React from 'react';
import PlatformCard from '../PlatformCard';
import BlockTitle from '../BlockTitle';
import './Platforms.scss';

const Platforms = ({ title, platforms, onChange, isFetching, index }) => (
  <div className="platforms-container">
    <BlockTitle index={index} title={title} />
    <div className="platforms">
      {platforms.map((v, i) => (
        <PlatformCard
          {...v}
          key={i}
          index={i}
          onChange={onChange}
          isFetching={isFetching}
        />
      ))}
    </div>
  </div>
);

export default Platforms;
