import { useEffect, useState, useCallback } from 'react';

const socket = new WebSocket(`${process.env.REACT_APP_BACKEND_WS_URL}/ws`);
const socketAdd = new WebSocket(
  `${process.env.REACT_APP_BACKEND_WS_URL}/ws_add_feature`
);

const useSocket = () => {
  const [value, setValue] = useState(null);
  const [valAll, setValAll] = useState(null);
  const [isGenerationCompleted, setIsGenerationCompleted] = useState(false);
  const [
    isFeatureGenerationCompleted,
    setIsFeatureGenerationCompleted
  ] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    socket.onmessage = event => {
      const data = event.data.split('|');
      try {
        if (data[0]) {
          let d = JSON.parse(data[0] || '');
          setIsFetching(true);
          if ('status' in d && d.status === 'Response ended') {
            setIsFetching(false);
            setIsGenerationCompleted(true);
          } else {
            setValue(d.filter(x => x['Feature Name'] !== 'Total Hours'));
          }
        }
      } catch (error) {
        console.log(error.message);
        setIsFetching(false);
      }
    };

    return () => {
      WebSocket.close();
    };
  }, []);

  useEffect(() => {
    socketAdd.onmessage = event => {
      const data = JSON.parse(event.data);
      setIsFetching(true);
      if (data[0]) {
        setValue([...(valAll ? valAll : []), ...data]);
      } else {
        if (Array.isArray(data)) {
          setValAll(value);
        }
        if ('status' in data && data.status === 'Response ended') {
          setIsFeatureGenerationCompleted(true);
          setValAll(value);
          setIsFetching(false);
        }
      }
    };
  }, [value, valAll]);

  const addFeature = useCallback(
    ({
      domain,
      competitor_name,
      product_version,
      add_feature_name,
      add_feature_descr,
      add_feature_roles,
      add_feature_platforms,
      idea
    }) => {
      const message = `${domain}|${competitor_name}|${product_version}|${add_feature_name}|${add_feature_descr}|${add_feature_roles}|${add_feature_platforms.map(
        x => x.title
      )}|${idea}`;
      setIsFetching(true);
      socketAdd.send(message);
    },
    []
  );

  const sendSocketData = useCallback(data => {
    const message = `${data.domain}|${data.competitor_name}|${
      data.product_version
    }|${data.roles}|${data.add_feature_platforms.map(x => x.title)}|${
      data.project_idea
    }`;
    setIsFetching(true);
    socket.send(message);
  }, []);

  const clearGenerationStatus = useCallback(() => {
    setIsGenerationCompleted(false);
  }, []);

  return {
    value,
    sendSocketData,
    setValue,
    addFeature,
    isGenerationCompleted,
    isFeatureGenerationCompleted,
    clearGenerationStatus,
    isFetching
  };
};

export default useSocket;
