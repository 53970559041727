import React from 'react';
import './Footer.scss';

const Footer = () => (
  <footer>
    <div className="col-9 footer">
      <div className="footer-logo-wrap-upd">
        <a href="https://geniusee.com/" className="footer-logo-wrapper">
          <img
            src="https://ik.imagekit.io/k0jemnbco/Group_7.svg"
            width="124"
            height="30"
            alt="Geniusee logo"
            className="footer-logo"
          />
        </a>
        <p className="font--14 pale no-hover">
          Geniusee — software product development services.
        </p>
        <div className="social-wrap-upd">
          <a
            className="social-icon-wrap"
            href="https://www.facebook.com/geniuseesoftware/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://ik.imagekit.io/k0jemnbco/icons/facebook-icon_pOwRbXvVm.svg"
              alt="Social Icon"
              width="10"
              height="20"
              loading="lazy"
            />
          </a>
          <a
            className="social-icon-wrap"
            href="https://www.linkedin.com/company/geniusee"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="https://ik.imagekit.io/k0jemnbco/icons/linkedin-icon_7EtYZVLqIu.svg"
              alt="Social Icon"
              width="18"
              height="18"
              loading="lazy"
            />
          </a>
          <a
            className="social-icon-wrap"
            href="https://www.behance.net/geniusee_software"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="https://ik.imagekit.io/k0jemnbco/icons/behance-icon_i8a2EDPAj.svg"
              alt="Social Icon"
              width="22"
              height="14"
              loading="lazy"
            />
          </a>
          <a
            className="social-icon-wrap"
            href="https://www.instagram.com/geniusee_software/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="https://ik.imagekit.io/k0jemnbco/icons/instagram-icon_hB0rjh8aG.svg"
              alt="Social Icon"
              width="18"
              height="18"
              loading="lazy"
            />
          </a>
          <a
            className="social-icon-wrap"
            href="https://twitter.com/GeniuseeSW"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="https://ik.imagekit.io/k0jemnbco/layer1.webp"
              alt="Social Icon"
              width="18"
              height="17"
              loading="lazy"
            />
          </a>
        </div>
        <div className="white-cards-wrapper">
          <div className="footer-white-card">
            <img
              src="https://ik.imagekit.io/k0jemnbco/Main_Page_New/Group_290841__1_.webp"
              alt="Clutch"
              width="114"
              height="25"
            />
          </div>
          <div className="footer-white-card">
            <img
              src="https://ik.imagekit.io/k0jemnbco/Main_Page_New/Group_2317468.svg"
              alt="ISO"
              width="117"
              height="34"
            />
          </div>
        </div>
      </div>
      <div className="links-wrapper-grid">
        <div className="footer-links">
          <div>
            <h4 className="font--h4">
              <a href="/digital-transformation-consulting" className="font--h4">
                Consulting
              </a>
            </h4>
            <ul>
              <li className="menu-item">
                <a className="font--14" href="/discovery-phase">
                  Discovery Phase
                </a>
              </li>
              <li className="menu-item">
                <a className="font--14" href="/technology-consulting-services">
                  Technology Consulting
                </a>
              </li>
              <li className="menu-item">
                <a className="font--14" href="/it-audit-services">
                  IT Audit Services
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="font--h4 h4-mt">
              <a href="/outstaffing" className="font--h4">
                Outstaffing
              </a>
            </h4>
            <ul>
              <li className="menu-item">
                <a className="font--14" href="/dedicated-team">
                  Dedicated Team
                </a>
              </li>
              <li className="menu-item">
                <a className="font--14" href="/time-and-material">
                  Time And Material
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="font--h4 h4-mt">
              <a
                href="/digital-transformation-consulting"
                className="font--h4 no-hover"
              >
                Product design
              </a>
            </h4>
            <ul>
              <li className="menu-item">
                <a className="font--14" href="/ui-ux-design">
                  Product Experience Design
                </a>
              </li>
              <li className="menu-item">
                <a className="font--14" href="/business-analysis">
                  Business Analysis
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-links">
          <h4 className="font--h4">
            <a href="/software" className="font--h4">
              Software Engineering
              <br />
              Services
            </a>
          </h4>
          <ul>
            <li className="menu-item">
              <a className="font--14" href="/mvp-startups">
                MVP Development
              </a>
            </li>
            <li className="menu-item">
              <a className="font--14" href="/digital-transformation">
                Digital Transformation
              </a>
            </li>
            <li className="menu-item">
              <a className="font--14" href="/web-development">
                Web Development
              </a>
            </li>
            <li className="menu-item">
              <a className="font--14" href="/mobile-development">
                Mobile Development
              </a>
            </li>
            <li className="menu-item">
              <a className="font--14 no-hover" href="#">
                Cloud Infrastructure
              </a>
            </li>
            <li className="menu-item">
              <a className="font--14" href="/devops">
                DevOps Engineering
              </a>
            </li>
            <li className="menu-item">
              <a className="font--14" href="/aws">
                Amazon Web Services
              </a>
            </li>
            <li className="menu-item">
              <a className="font--14" href="/integration">
                Integration Services
              </a>
            </li>
          </ul>
          <div>
            <h4 className="font--h4 h4-mt">
              <a href="/ai-powered-app-development" className="font--h4">
                AI-powered App Development
              </a>
            </h4>
          </div>
        </div>
        <div className="footer-links">
          <div>
            <h4 className="font--h4">
              <a href="/fintech" className="font--h4">
                FinTech
              </a>
            </h4>
            <ul>
              <li className="menu-item">
                <a className="font--14" href="/ewallet">
                  e-Wallet
                </a>
              </li>
              <li className="menu-item">
                <a className="font--14" href="/banking-software-development">
                  Banking
                </a>
              </li>
              <li className="menu-item">
                <a className="font--14" href="/lending-software-development">
                  Lending
                </a>
              </li>
            </ul>
          </div>
          <div>
            <div>
              <h4 className="font--h4 h4-mt">
                <a href="/edtech" className="font--h4">
                  EdTech
                </a>
              </h4>
              <ul>
                <li className="menu-item">
                  <a className="font--14" href="/e-learning">
                    e-Learning
                  </a>
                </li>
                <li className="menu-item">
                  <a className="font--14" href="/lms">
                    LMS
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font--h4 h4-mt">
                <a href="/retail" className="font--h4">
                  Retail
                </a>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-9 footer-copyright-upd">
      <div>
        <p className="font--14 pale no-hover">
          ©Copyright 2024, All rights reserved.
        </p>
      </div>
      {/* <div>
        <a href="#" className="font--14 pale" style="display: none">
          Terms &amp; Conditions
        </a>
      </div> */}
      <div>
        <a href="/privacy-policy" className="font--14 pale">
          Privacy Policy
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
