import React, { useState, useCallback, useEffect } from 'react';
import ReactGA from 'react-ga';
import env from '../../config/env.json';
import Image from '../Image';
import './Header.scss';

const handleLinkPress = link => () => {
  ReactGA.event({
    category: 'Navigate',
    action: link
  });
};

const Header = () => {
  const [mobile, setMobile] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const handleToggle = useCallback(() => {
    setMobile(mobile => !mobile);
  }, []);
  const handleClose = useCallback(() => {
    setMobile(false);
  }, []);

  const handleColor = () => {
    if (window.scrollY > 0) {
      document.querySelector('.header').classList.add('white');
    } else {
      document.querySelector('.header').classList.remove('white');
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleColor);

    return () => document.removeEventListener('scroll', handleColor);
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth < 650);
  }, [isMobile]);

  return (
    <>
      <div className="header">
        <a className="logo" href="/">
          <Image src={'/images/logo'} />
          <Image src={'/images/AI'} className={'AIimage'} />
        </a>
        <div
          className={`mobile-toggle${mobile ? ' mobile' : ''}`}
          onClick={handleToggle}
        >
          <span></span>
          <span></span>
        </div>
        <div
          className={`mask${mobile ? ' mobile' : ''}`}
          onClick={handleClose}
        />
        <div className={`links${mobile ? ' mobile' : ''}`}>
          {/* <a onClick={handleLinkPress('about')} href={`${env.baseUrl}`}>
            {isMobile ? 'geniusee.com' : 'Back to geniusee.com'}
          </a> */}
        </div>
      </div>
    </>
  );
};

export default Header;
